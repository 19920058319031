import axios from '../axios';

export const all = (lang) => {
  return axios.get(`/hero-section-carousel/all-for-admin?lang=${lang}`);
};

export const submit = (payload) => {
  return axios.post('/hero-section-carousel', payload);
};

export const submitGeneralItems = (payload) => {
  return axios.post('/hero-section-carousel/main', payload);
};

export const generalItems = (lang) => {
  return axios.get(`/hero-section-carousel/main?lang=${lang}`);
};
