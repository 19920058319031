<template>
  <b-card>
    <form-component
      :all-categories="allCategories"
      :categories="items"
      @onSubmit="onSubmit"
      @fetchByLocale="onFetchByLocale" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/elements/carousel-items/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';

import { submitGeneralItems, all as allCarouselElements, generalItems } from '@/http/hero-section-carousel';
import { all as allCategories } from '@/http/navigation';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      categoryGroups: [],
      items: [],
    };
  },

  async created() {
    await this.fetchCategories();
  },

  computed: {
    allCategories() {
      return this.categoryGroups
        .map((group) => {
          return group.navigation_subgroups
            .map((subgroup) => {
              return subgroup.navigation_elements
                .map((element) => {
                  return {
                    name: element.name,
                    id: element.id,
                  };
                })
                .flat();
            })
            .flat();
        })
        .flat();
    },
  },
  methods: {
    async fetchCategories(lang = 'pl') {
      allCategories({ lang })
        .then(({ data }) => {
          this.categoryGroups = data.data;
        })
        .catch((error) => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        })
        .finally(async () => {
          await this.fetchByLocale(lang);
        });
    },

    async onFetchByLocale(data) {
      await this.fetchCategories(data);
    },

    async fetchByLocale(data) {
      generalItems(data)
        .then(({ data }) => {
          this.items = data;
        })
        .catch((error) => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    async onSubmit(data) {
      const form = new FormData();

      data.items.forEach((category, index) => {
        if (typeof category.id !== 'string') {
          form.append(`items[${index}][id]`, category.id);
        }
        form.append(`items[${index}][navigation_element_id]`, category.navigation_element_id);
        form.append(`items[${index}][title]`, category.title);
        form.append(`items[${index}][button_text]`, category.button_text);
        form.append(`items[${index}][image]`, category.image);
        form.append(`items[${index}][is_external_link]`, category.is_external_link ? 1 : 0);
        form.append(`items[${index}][external_link]`, category.external_link);
        form.append(`items[${index}][order]`, category.order);
      });
      form.append('lang', data.lang);

      submitGeneralItems(form)
        .then(() => {
          this.fetchByLocale(form.get('lang'));
          this.showSuccessNotification('Dane zapisane', 'Dane zostały zapisane pomyślnie.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
